import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import { Button } from 'react-bootstrap';
import 'leaflet/dist/leaflet.css';
import "./map.css";

// マーカーのアイコンを設定
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const LeafletMap = ({ position, setPosition }) => {
  // const [position, setPosition] = useState([35.681236, 139.767125]);
  const [mapKey, setMapKey] = useState(0);
  const [zoom, setZoom] = useState(12);

  // ===================位置情報の取得==========================
  // 位置情報の取得
  function GetGeolocation() {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }
  // 正常に取得できたとき
  function successCallback(position) {
    var latitude = position.coords.latitude; // 緯度の取得
    var longitude = position.coords.longitude; // 経度の取得
    setPosition([latitude, longitude]); // 取得した経緯度を基に更新
    setZoom(16); // マップをズームする
    setMapKey(new Date().getTime()); // マップを更新するためにキーを更新
  }
  // 取得に失敗したとき 
  function errorCallback(error) {
    alert('位置情報が取得できませんでした');
  }
  // ============================================================

  // マップをクリックしたとき
  const MapClickHandler = () => {
    useMapEvents({
      click(e) {
        setPosition([e.latlng.lat, e.latlng.lng]);
      },
    });
    return null;
  };

  return (
    <>
      <MapContainer center={position} zoom={zoom} scrollWheelZoom={true} key={mapKey}>
        <TileLayer
          attribution='© OpenStreetMap contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MapClickHandler />
        <Marker position={position}>
          {/* <Popup>You are here</Popup> */}
        </Marker>
      </MapContainer>

      <div className='d-grid mt-1'>
        <Button className='general_button mb-3' variant='success' onClick={GetGeolocation}>現在位置を取得</Button>
      </div>
    </>
  );
}

export default LeafletMap;

