import React from 'react';
import { Button } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import { HeaderR } from './routes/c/header';
import { Link } from 'react-router-dom';

// CSS
import "./general.css";

const Page404 = () => {
    return (
        <>
            <HeaderR title="ページが存在しません" />
            <div style={{ "margin": "40px" }}>
                <h1 style={{ "color": "#2cb4ad" }}>404 Not Found</h1>
            </div>
            <Link to="/">
                <Button className='general_button' variant='success'>トップページにもどる</Button>
            </Link>
        </>
    )
}

export default Page404;
