import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { mediaQuery, UseMediaQuery } from "../../../useMediaQuery";
import LeafletMap from "./map";
import Record_form from "./form";
import WebcamCapture from "./cam";

// CSS
import "../../../general.css";

const Record_index = () => {
  // 使用デバイスがモバイルサイズかどうか
  const isSp = UseMediaQuery(mediaQuery.sp);
  // キャプチャー済みかどうか
  const [captured, setCaptured] = useState(false);
  // 写真のキャプチャ内容の保存用
  const [photo, setPhoto] = useState("");
  // 写真をキャプチャしたら、内容を格納
  const handlePhotoCapture = (dataUrl) => {
    setPhoto(dataUrl);
  };

  return (
    <>
      {!captured ? (
        <>
          <WebcamCapture
            onPhotoCapture={handlePhotoCapture}
            setCaptured={setCaptured}
          />
        </>
      ) : (
        <Record_form photo={photo} />
      )}
      {isSp ? ( //モバイル表示
        <></>
      ) : (
        //デスクトップ表示
        <></>
      )}
    </>
  );
};

export default Record_index;
