import React, { useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { HeaderRB } from "../header";
import shutterSound from './Camera-Phone02-1.mp3'
import "./cam.css";

const WebcamCapture = ({ onPhotoCapture, setCaptured }) => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    useEffect(() => {
        const getVideo = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } });
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            } catch (err) {
                console.error('Error accessing camera: ', err);
            }
        };

        getVideo();
    }, [videoRef]);

    const takePhoto = () => {
        const audio = new Audio(shutterSound);
        audio.play();

        const width = 1800;
        const height = 2400;
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;
        context.drawImage(video, 0, 0, width, height);

        const dataUrl = canvas.toDataURL('image/jpeg');
        onPhotoCapture(dataUrl);
        setCaptured(true);
        // console.log(dataUrl); // ここで写真のデータURLを取得できます
    };

    return (
        <>
            {/* <HeaderRB title="ポイ捨てを報告" /> */}
            <HeaderRB />
            <div className='webcam_container'>
                <video className='webcam position-absolute start-0 translate-middle-y' ref={videoRef} autoPlay />
                <Button className='shutterButton position-absolute start-50 translate-middle-x' variant='light' onClick={takePhoto}></Button>
                <canvas ref={canvasRef} style={{ display: 'none' }} />
            </div>
        </>
    );
};

export default WebcamCapture;



// import React, { useRef, useCallback } from 'react';
// import { Button } from 'react-bootstrap';
// import Webcam from 'react-webcam';
// import shutterSound from './Camera-Phone02-1.mp3'
// import "./cam.css";

// const WebcamCapture = () => {
//     const webcamRef = useRef(null);

//     const capture = useCallback(() => {
//         const imageSrc = webcamRef.current.getScreenshot();
//         const audio = new Audio(shutterSound);
//         audio.play();
//         console.log(imageSrc); // ここで画像データを処理します
//     }, [webcamRef]);

//     return (
//         <div style={{ backgroundColor: "#000", height: "calc(100vh - 70px)" }}>
//             <Webcam
//                 className='position-absolute top-50 start-0 translate-middle-y'
//                 audio={false}
//                 ref={webcamRef}
//                 screenshotFormat="image/jpeg"
//                 width="100%"
//                 // height="100%"
//                 videoConstraints={{ facingMode: "environment" }}
//                 onUserMediaError={() => window.alert('cant access your camera')}
//             />
//             <Button className='shutterButton position-absolute start-50 translate-middle-x' variant='light' onClick={capture}></Button>
//         </div>
//     );
// };

// export default WebcamCapture;
