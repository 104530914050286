import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { mediaQuery, UseMediaQuery } from "../../../useMediaQuery";
import { HeaderX } from "../header";
import LeafletMap from "./map";

// CSS
import "../../../general.css";
// import WebcamCapture from "./cam";

const Record_form = ({ photo }) => {
    // 使用デバイスがモバイルサイズかどうか
    const isSp = UseMediaQuery(mediaQuery.sp);
    // 地図の座標
    const [position, setPosition] = useState([35.681236, 139.767125]);
    // 画面遷移用
    const navigate = useNavigate()

    const handleSubmit = () => {
        navigate('/');
    }

    return (
        <>
            <HeaderX title="ポイ捨てを報告" />
            <div className="m-3">
                <Row>
                    <Col xs={12} md={3}>
                        <Form className="text-start">
                            <Form.Group className="mt-1">
                                <Form.Label>撮影したゴミの写真</Form.Label>
                                {photo ? <img src={photo} width="100%" alt="Captured" /> : <p>No photo captured</p>}
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form className="text-start">
                            <Form.Group className="mt-4">
                                <Form.Label>ゴミを見つけた場所を選択してください</Form.Label>
                                <LeafletMap position={position} setPosition={setPosition} />
                            </Form.Group>
                        </Form>
                    </Col>

                    <Col xs={12} md={6}>
                        <Form className="text-start" >
                            <Form.Group className="mt-3">
                                <Form.Label>ゴミの場所の詳細</Form.Label>
                                <Form.Control
                                    id="record_form_place"
                                    rows="3"
                                    as="textarea"
                                    placeholder="例）&#13;&#10;「道路脇の植え込み」　「〇〇の自販機の下」"
                                    style={{ resize: "none" }}
                                />
                            </Form.Group>

                            <Form.Group className="mt-4">
                                <Form.Label>ゴミの種類</Form.Label>
                                <Form.Select id="record_form_sort" type="text">
                                    <option>選択してください</option>
                                    <option value="1">ペットボトル</option>
                                    <option value="2">缶</option>
                                    <option value="3">飲料容器（ペットボトル、缶を除く）</option>
                                    <option value="4">吸い殻 / タバコの箱</option>
                                    <option value="4">傘</option>
                                    <option value="5">プラスチックのゴミ</option>
                                    <option value="6">紙製のゴミ</option>
                                    <option value="7">金属製のゴミ</option>
                                    <option value="8">その他</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mt-4">
                                <Form.Label>捨てられた製品のメーカー/販売会社</Form.Label>
                                <Form.Control
                                    id="record_form_maker"
                                    type="text"
                                    placeholder="わかる範囲で記入してください"
                                />
                            </Form.Group>

                            <Form.Group className="mt-4">
                                <Form.Label>捨てられた製品の商品名</Form.Label>
                                <Form.Control
                                    id="record_form_goods"
                                    type="text"
                                    placeholder="わかる範囲で記入してください"
                                />
                            </Form.Group>

                            <Form.Group className="mt-5 d-grid">
                                <Button variant="primary" onClick={handleSubmit}>
                                    送信
                                </Button>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>

                {isSp ? ( //モバイル表示
                    <></>
                ) : (
                    //デスクトップ表示
                    <></>
                )}
            </div>
        </>
    );
};


export default Record_form;
