import React from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Col, Container, Image, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { mediaQuery, UseMediaQuery } from "../../../useMediaQuery";
import { Header } from "../header";

// CSS
import "../../../general.css";

const Home = () => {
  // 使用デバイスがモバイルサイズかどうか
  const isSp = UseMediaQuery(mediaQuery.sp);

  return (
    <>
      <Header title="poypoy" />
      <div className="m-3">
        {isSp ? ( //モバイル表示
          <>
            <Row>
              <Col xs={6}>
                <Link to="/record">
                  <Image src="/img/gomihiroi.png" width="100%" roundedCircle />
                  <label>ゴミを拾う</label>
                </Link>
              </Col>
              <Col xs={6}>
                <Link to="/record">
                  <Image src="/img/camera.png" width="100%" roundedCircle />
                  <label>写真を撮る</label>
                </Link>
              </Col>
            </Row>
          </>
        ) : (
          //デスクトップ表示
          <>
            <Alert variant="warning">
              スマートフォンでのご利用を推奨しております
            </Alert>
          </>
        )}
      </div>
    </>
  );
};

export default Home;
